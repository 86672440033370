import _ from "lodash";
import axios from "axios";

export default {
  data() {
    return {
      session: undefined,
    };
  },

  stored: {
    locale: {
      type: String,
      key: "sofistiq/locale",
      default() {
        return this.$i18n.fallbackLocale;
      },
    },
    session_token: {
      type: String,
      key: "sofistiq/session/token",
    },
  },

  computed: {
    languages: _.constant({
      af: "Afrikaans",
      ar: "\u0627\u0644\u0639\u0631\u0628\u064A\u0629",
      az: "az\u0259rbaycan dili",
      be: "\u0431\u0435\u043B\u0430\u0440\u0443\u0441\u043A\u0430\u044F \u043C\u043E\u0432\u0430",
      bg: "\u0431\u044A\u043B\u0433\u0430\u0440\u0441\u043A\u0438 \u0435\u0437\u0438\u043A",
      cs: "\u010De\u0161tina",
      da: "dansk",
      de: "Deutsch",
      el: "\u03B5\u03BB\u03BB\u03B7\u03BD\u03B9\u03BA\u03AC",
      en: "English",
      es: "Espa\u00F1ol",
      et: "eesti",
      fi: "suomi",
      fr: "fran\u00E7ais",
      hr: "hrvatski jezik",
      hy: "\u0540\u0561\u0575\u0565\u0580\u0565\u0576",
      id: "Bahasa Indonesia",
      it: "Italiano",
      ja: "\u65E5\u672C\u8A9E",
      ka: "\u10E5\u10D0\u10E0\u10D7\u10E3\u10DA\u10D8",
      ko: "\uD55C\uAD6D\uC5B4",
      nl: "Nederlands",
      no: "Norsk",
      pl: "Polszczyzna",
      pt: "Portugu\u00EAs",
      rm: "Rumantsch Grischun",
      ru: "\u0420\u0443\u0441\u0441\u043A\u0438\u0439",
      sq: "Shqip",
      sv: "Svenska",
      tr: "T\u00FCrk\u00E7e",
      uk: "\u0423\u043A\u0440\u0430\u0457\u043D\u0441\u044C\u043A\u0430",
      zh: "\u4E2D\u6587",
    }),
    locales() {
      return _.keys(this.languages);
    },
  },

  asyncComputed: {
    ready: {
      async get() {
        try {
          let { session_token } = this;

          if (_.isEmpty(session_token)) {
            throw new Error("Session token not found");
          }

          let { data } = await axios({
            method: "get",
            url: "/api/me",
            headers: { Authorization: session_token },
          });

          let session = {
            token: session_token,
            account: {
              id: data.id,
              created: data.created,
              name: data.name,
              email: data.email,
              admin: data.admin,
              available_interviews_count: _.defaultTo(
                data.available_interviews_count,
                Infinity
              ),
              available_features: data.available_features,
            },

            get user() {
              return this.account;
            },
          };

          _.assign(this, { session });
        } catch (error) {
          console.log(error);
          _.assign(this, { session: null });
        }

        return true;
      },
      default: false,
    },
  },

  metaInfo() {
    let { $vuetify, $t } = this;

    let t = $t.bind(this);

    return {
      title: t("utprmnovmoow"),
      titleTemplate: "%s | rep.bit",
      meta: [
        {
          charset: "utf-8",
        },
        {
          content: ["width=device-width", "initial-scale=1.0"].join(", "),
          name: "viewport",
        },
        {
          content: $vuetify.theme.themes.light.primary,
          name: "theme-color",
        },
      ],

      link: [
        {
          href: (() => {
            let base_url = new URL(process.env.BASE_URL, origin);
            let url = new URL("favicon.svg", base_url);
            return `${url}`;
          })(),
          rel: "icon",
          sizes: "any",
          type: "image/svg+xml",
        },
      ],
    };
  },

  watch: {
    ready(ready) {
      if (ready) {
        this.$router.start();
      }
    },

    locale: {
      handler(value) {
        this.$i18n.locale = value;
      },
      immediate: true,
    },
  },

  methods: {
    del_session() {
      this.session_token = null;
    },
  },
};
