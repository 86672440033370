import "@fontsource/montserrat/100.css";
import "@fontsource/montserrat/200.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/nunito/400.css";
import "@mdi/font/css/materialdesignicons.min.css";
import "animate.css";
import "vuetify/dist/vuetify.min.css";

import "./style.css";

import Vuetify from "vuetify";

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {
      success: "mdi-check-circle",
      info: "mdi-information",
      warning: "mdi-alert",
      error: "mdi-alert-octagon",
    },
  },
  theme: {
    options: {
      //variations: false,
    },
    themes: {
      light: {
        primary: "#00b295",
        get secondary() {
          return this.accent;
        },
        accent: "#4f66cf",
        error: "#c0180c",
        info: "#00b295",
        get success() {
          return this.primary;
        },
        get warning() {
          return this.error;
        },
      },
      get dark() {
        return this.light;
      },
    },
  },
});
