import VueI18n from "vue-i18n";

import messages from "./messages";

export default new VueI18n({
  fallbackLocale: "en",
  locale: "en",
  messages,
  silentTranslationWarn: true,
});
