import VueRouter from "vue-router";

let router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/i",
      component() {
        return import("@/pages/interview_tool");
      },
      children: [
        {
          path: "entry/:token",
          name: "interview_tool/entry",
          component() {
            return import("@/pages/interview_tool/pages/entry");
          },
          props: true,
        },
        {
          path: ":token",
          name: "interview_tool",
          component() {
            return import("@/pages/interview_tool/pages/index");
          },
          props: true,
        },
        {
          path: ":token/end",
          name: "interview_tool/end",
          component() {
            return import("@/pages/interview_tool/pages/end");
          },
          props: true,
        },
        {
          path: "*",
          redirect: { name: "front" },
        },
      ],
    },
    {
      path: "/a/stats/:analysis_token",
      name: "statistical_analysis_tool",
      component() {
        return import("@/pages/statistical_analysis_tool");
      },
      props: true,
      children: [
        {
          path: "elements/spread",
          name: "statistical_analysis_tool/elements_interviews_distances",
          component() {
            return import(
              "@/pages/statistical_analysis_tool/pages/elements_interviews_distances"
            );
          },
        },
        {
          path: "elements/variance",
          name: "statistical_analysis_tool/elements_variances",
          component() {
            return import(
              "@/pages/statistical_analysis_tool/pages/elements_variances"
            );
          },
        },
        {
          path: "elements/fulfillment-degree",
          name: "statistical_analysis_tool/elements_fulfillment_degrees",
          component() {
            return import(
              "@/pages/statistical_analysis_tool/pages/elements_fulfillment_degrees"
            );
          },
        },
        {
          path: "elements/potential-matrix",
          name: "statistical_analysis_tool/elements_potential_matrix",
          component() {
            return import(
              "@/pages/statistical_analysis_tool/pages/elements_potential_matrix"
            );
          },
        },
        {
          path: "elements/distance",
          name: "statistical_analysis_tool/elements_elements_distances",
          component() {
            return import(
              "@/pages/statistical_analysis_tool/pages/elements_elements_distances"
            );
          },
        },
        {
          path: "constructs/cloud",
          name: "statistical_analysis_tool/constructs_cloud",
          component() {
            return import(
              "@/pages/statistical_analysis_tool/pages/constructs_cloud"
            );
          },
        },
        {
          path: "constructs/spread",
          name: "statistical_analysis_tool/constructs_interviews_distances",
          component() {
            return import(
              "@/pages/statistical_analysis_tool/pages/constructs_interviews_distances"
            );
          },
        },
        {
          path: "constructs/variance",
          name: "statistical_analysis_tool/constructs_variances",
          component() {
            return import(
              "@/pages/statistical_analysis_tool/pages/constructs_variances"
            );
          },
        },
        {
          path: "*",
          redirect: { name: "statistical_analysis_tool" },
        },
      ],
    },
    {
      path: "/db",
      component() {
        return import("@/pages/dashboard");
      },
      beforeEnter(to, from, next) {
        let { session } = router.app;
        if (session) {
          next();
        } else {
          next({ name: "front" });
        }
      },
      children: [
        {
          path: "me",
          name: "dashboard/session_account",
          component() {
            return import("@/pages/dashboard/pages/session_account");
          },
        },
        {
          path: "accounts",
          name: "dashboard/accounts",
          component() {
            return import("@/pages/dashboard/pages/accounts");
          },
          beforeEnter(to, from, next) {
            let { session } = router.app;
            if (session.account.admin) {
              next();
            } else {
              next({ name: "dashboard" });
            }
          },
        },
        {
          path: "accounts/:account_id",
          name: "dashboard/account",
          component() {
            return import("@/pages/dashboard/pages/account");
          },
          props({ params }) {
            let { account_id } = params;
            account_id = Number.parseInt(account_id);
            return { ...params, account_id };
          },
          beforeEnter(to, from, next) {
            let { session } = router.app;
            if (session.account.admin) {
              next();
            } else {
              next({ name: "dashboard" });
            }
          },
        },
        {
          path: "projects",
          name: "dashboard/projects",
          component() {
            return import("@/pages/dashboard/pages/projects");
          },
        },
        {
          path: "projects/:project_id",
          name: "dashboard/project",
          component() {
            return import("@/pages/dashboard/pages/project");
          },
          props({ params }) {
            let { project_id } = params;
            project_id = Number.parseInt(project_id);
            return { ...params, project_id };
          },
        },
        {
          path: "projects/:project_id/interview-settings",
          name: "dashboard/interview_settings",
          component() {
            return import("@/pages/dashboard/pages/interview_settings");
          },
          props({ params }) {
            let { project_id } = params;
            project_id = Number.parseInt(project_id);
            return { ...params, project_id };
          },
        },
        {
          path: "projects/:project_id/interviews",
          name: "dashboard/interviews",
          component() {
            return import("@/pages/dashboard/pages/interviews");
          },
          props({ params }) {
            let { project_id } = params;
            project_id = Number.parseInt(project_id);
            return { ...params, project_id };
          },
        },
        {
          path: "projects/:project_id/interviews/:interview_id",
          name: "dashboard/interview",
          component() {
            return import("@/pages/dashboard/pages/interview");
          },
          props({ params }) {
            let { project_id, interview_id } = params;
            project_id = Number.parseInt(project_id);
            interview_id = Number.parseInt(interview_id);
            return { ...params, project_id, interview_id };
          },
        },
        {
          path: "projects/:project_id/analyses",
          name: "dashboard/analyses",
          redirect({ params: { project_id } }) {
            return {
              name: "dashboard/project",
              params: {
                project_id,
              },
            };
          },
        },
        {
          path: "projects/:project_id/analyses/:analysis_id",
          name: "dashboard/analysis",
          component() {
            return import("@/pages/dashboard/pages/analysis");
          },
          props({ params }) {
            let { project_id, analysis_id } = params;
            let { session } = router.app;
            project_id = Number.parseInt(project_id);
            analysis_id = Number.parseInt(analysis_id);
            return { ...params, project_id, analysis_id, session };
          },
        },
        {
          path: "",
          name: "dashboard",
          redirect: { name: "dashboard/projects" },
        },
        {
          path: "*",
          redirect: { name: "dashboard" },
        },
      ],
    },
    {
      path: "/",
      component() {
        return import("@/pages/front");
      },
      children: [
        {
          path: "",
          name: "front",
          component() {
            return import("@/pages/front/pages/index");
          },
        },
        {
          path: "sign-up",
          name: "front/add_request_to_add_account",
          component() {
            return import("@/pages/front/pages/add_request_to_add_account");
          },
          props: true,
        },
        {
          path: "sign-up/:token",
          name: "front/confirm_request_to_add_account",
          component() {
            return import("@/pages/front/pages/confirm_request_to_add_account");
          },
          props: true,
        },
        {
          path: "reset-password",
          name: "front/add_request_to_set_account_password",
          component() {
            return import(
              "@/pages/front/pages/add_request_to_set_account_password"
            );
          },
          props: true,
        },
        {
          path: "reset-password/:token",
          name: "front/confirm_request_to_set_account_password",
          component() {
            return import(
              "@/pages/front/pages/confirm_request_to_set_account_password"
            );
          },
          props: true,
        },
        {
          path: "*",
          redirect: { name: "front" },
        },
      ],
    },
    {
      path: "/*",
      redirect: {
        name: "front",
      },
    },
  ],
});

let start_promise = new Promise((resolve) => {
  router.start = resolve;
});

router.beforeEach((to, from, next) => {
  start_promise.then(next);
});

export default router;
