import Vue from "vue";

import i18n from "../i18n";
import router from "../router";
import vuetify from "../vuetify";

import component from "./component";

const app = new (Vue.extend(component))({
  i18n,
  router,
  vuetify,
});

export default app;
